import * as type from '../../types/trans';
import {AUTHRESP,TYPE_LOGIN} from '../../types/onboarding';
import  { HEADER_KEY } from '../../types/preloginType';

export const loginTypeCheck = (data) => {    
    return {
        type: TYPE_LOGIN,
        payload: data
    }
}

export const cvlData = (data) => {    
    return {
        type: type.SAVE_CVL_DATA,
        payload: data
    }
}

export const authDetails = (data) => {    
    return {
        type: AUTHRESP,
        payload: data
    }
}

export const HeaderKey = (data) => {
    return {
        type: HEADER_KEY,
        payload: data
    }
}
