import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { INIT_GET_FOLIO_LIST, LOGGED_IN_SUCCESS, LOGOUT } from '../redux/types/init';
import { INIT_APP } from '../redux/types/login';
import { initGetFolioList, loggedInSuccess } from './init';
import { initApp } from './login';
import { logout } from './logout';

import { AUTHENTICATE_USER } from '../redux/types/authenticate';
import { aboutUs } from './aboutUs';
import { authenticateUser } from './authenticate';
import { Category, DropFilter, MFDetail, STPCategory } from './mfDetail';
import { NavApi } from './nav';
import { getMaster } from './onboarding/master';
import { callCVL, clientDetails, getIfscBankDet, getKycFolioData, panDetail, saveBankDetails, saveFatcaDetails, saveNomineeDetails, savePersonalDetails, validateNewInvPAN } from './onboarding/personal';
import { PassiveCategory, PassiveDetail } from './passivefund';
import { getCartData, getEUINList } from './trans/cartSaga';
import { FolioDescription, addToCart, getAllFolios, getSchemeMasterDetails, getSipDashboard, getStpDashboard, getSwpDashboard, portFolioCall, singleCartData } from './trans/transSaga';

import { getFolioDetails, setFolioValue } from './manageBank';
import { orderBookCall } from './trans/orderBook';

import { CANCEL_TRANSACTION, GET_CONFIRMATION_INFO, GET_DIT_TOKEN, SAVE_TRANSACTION } from "../redux/types/confirmation";
import { ADD_REPORT_ISSUE, ADD_SUGGESTION, FILE_UPLOAD, GET_DIT_REPORT_LIST, SUBMIT_DIT_REPORT } from "../redux/types/ditReport";
import { FABSCHEME, FOLIODETAILS, FREQUENCYCALCULATOR, WITHDRAWPLOTTING } from '../redux/types/fabPlan';
import { MENU_ROLE_WISE } from '../redux/types/initApp';
import { VERIFY_OTP } from '../redux/types/redeemotp';
import { BITALY_LINK, CLIENT_EXIST_DATA, CLIENT_MF_DATA, CapitalGainLoss_DATA, GetMFAccountStatement_DATA, REPORT_SEND, SIPDashboard_DATA } from '../redux/types/reports';
import { EUIN_LIST } from '../redux/types/trans';
import { VALIDATE_OTP } from '../redux/types/validateotp';
import { ValidateRedeemOTP, redeemOtp } from './authenticate';
import { cancelTransaction, ditToken, getConfirmationInfo, saveTransaction } from './confirmation';
import { addReportIssue, addSuggestionData, fileUpload, getDitReportList, submitDitReport } from './ditReport';
import { fabScheme, folioDetails, frequencyCalculator, withdrawPlotting } from './fabPlan';
import { menuRoleWise } from './initApp';
import { Bitaly_link, CapitalGainLoss, ClientMF, GetMFAccountStatement, ReportSend, SIPDashboard, checkClientExist } from './reports';
import { removeCookie } from './removeCookie';
import { REMOVE_COOKIE } from '../redux/types/removeCookies';


function* rootSaga() {
        yield all([
                takeEvery(INIT_APP, initApp), // (ACTION, SAGA)
                takeEvery(INIT_GET_FOLIO_LIST, initGetFolioList),
                fork(aboutUs),
                fork(NavApi),
                fork(Category),
                fork(MFDetail),
                fork(DropFilter),
                fork(PassiveCategory),
                fork(PassiveDetail),
                fork(getMaster),
                fork(callCVL),
                fork(validateNewInvPAN),
                fork(panDetail),
                fork(clientDetails),
                fork(getKycFolioData),
                fork(getIfscBankDet),
                fork(saveBankDetails),
                fork(saveNomineeDetails),
                fork(saveFatcaDetails),
                fork(savePersonalDetails),
                fork(portFolioCall),
                fork(STPCategory),
                fork(addToCart),
                fork(singleCartData),
                fork(getSipDashboard),
                fork(FolioDescription),
                fork(getSwpDashboard),
                fork(getStpDashboard),
                fork(getFolioDetails),
                fork(getAllFolios),
                // fork(setBankDetails),
                fork(setFolioValue),
                fork(orderBookCall),
                fork(getCartData),
                fork(getSchemeMasterDetails),
                takeEvery(LOGGED_IN_SUCCESS, loggedInSuccess),
                takeEvery(LOGOUT, logout),
                takeEvery(AUTHENTICATE_USER, authenticateUser),

                takeLatest(GET_CONFIRMATION_INFO, getConfirmationInfo),
                takeLatest(GET_DIT_TOKEN, ditToken),
                takeLatest(SAVE_TRANSACTION, saveTransaction),
                takeLatest(CANCEL_TRANSACTION, cancelTransaction),
                //dit Reports
                takeLatest(GET_DIT_REPORT_LIST, getDitReportList),
                takeLatest(SUBMIT_DIT_REPORT, submitDitReport),
                takeLatest(MENU_ROLE_WISE, menuRoleWise),
                takeLatest(ADD_REPORT_ISSUE, addReportIssue),
                takeLatest(ADD_SUGGESTION, addSuggestionData),
                takeLatest(FILE_UPLOAD, fileUpload),

                // Reports
                takeLatest(CLIENT_EXIST_DATA, checkClientExist),
                takeLatest(CLIENT_MF_DATA, ClientMF),
                takeLatest(GetMFAccountStatement_DATA, GetMFAccountStatement),
                takeLatest(CapitalGainLoss_DATA, CapitalGainLoss),
                takeLatest(SIPDashboard_DATA, SIPDashboard),
                takeLatest(VALIDATE_OTP, ValidateRedeemOTP),
                takeLatest(VERIFY_OTP, redeemOtp),
                takeLatest(BITALY_LINK, Bitaly_link),
                takeLatest(REPORT_SEND, ReportSend),

                // fabscheme
                takeLatest(FABSCHEME, fabScheme),
                takeLatest(FREQUENCYCALCULATOR, frequencyCalculator),
                takeLatest(FOLIODETAILS, folioDetails),
                takeLatest(WITHDRAWPLOTTING, withdrawPlotting),
                //EUIN
                takeLatest(EUIN_LIST, getEUINList),

                takeLatest(REMOVE_COOKIE, removeCookie)

        ]);
}

export default rootSaga;